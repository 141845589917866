import React from 'react';
import './styles/Courses.css';
import { Link } from 'react-router-dom';
import coursesInfo from '../../data/coursesInfo.json';
import ListPrice from '../ListPrice';
import DateCalculator from '../DateCalculator';
import Spline from '@splinetool/react-spline';

const TopicSection = ({ title, content }) => (
  <div>
    <li className="Course-topic-title">{title}</li>
    <ul className="Course-topics-list">
      {content.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const JavaAndSpringBoot = () => {
  const course = coursesInfo[0].courses.find(
    (course) => course.courseName === 'Back-End Java and AWS'
  );

  const common = coursesInfo[0].commonCourseData;

  const { sale, prerequisites, links, duration, schedule } = course;

  const courseDurationWeeks = parseInt(duration.split(' ')[0]);

  const scheduleDays = schedule.map((item) => item.day);
  const startDay = scheduleDays[0];
  const endDay = scheduleDays[scheduleDays.length - 1];

  const firstPeriod = DateCalculator({
    courseDuration: courseDurationWeeks,
    scheduleDays: scheduleDays,
    futureMonths: 1,
    startDay: startDay,
    endDay: endDay,
  });

  const secondPeriod = DateCalculator({
    courseDuration: courseDurationWeeks,
    scheduleDays: scheduleDays,
    futureMonths: 2,
    startDay: startDay,
    endDay: endDay,
  });

  const renderPrerequisites = (part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      const key = part.slice(1, -1);
      return (
        <Link key={index} to={links[key]}>
          {key.replace(/([A-Z])/g, ' $1').trim()}
        </Link>
      );
    }
    return part;
  };

  return (
    <div className="Course-container">
      <div className="Course-top-main-content">
        <div className="spline-background-wrapper">
          <Spline
            className="spline-background"
            scene="https://prod.spline.design/nVCuZxBvl97lYUQx/scene.splinecode"
          />
          <h1 className="Course-name-overlay">{course.courseName}</h1>
        </div>

        <div className="Course-overview">
          <h2 className="Course-header">Overview</h2>
          <p className="Course-details">{course.overview}</p>
        </div>

        <div className="Course-sidebar">
          <Link to="/ApplicationForm" className="Course-apply-btn">
            Apply
          </Link>
          <div className="Course-schedule">
            <h3 className="Course-header-side">Days</h3>

            {schedule.map((item, index) => (
              <p key={index}>
                {item.day} {item.time}
              </p>
            ))}

            <br/>

          
            <p className="Course-side-details">New class begins first week of each month</p>

            
            
          </div>
          <div>
            <h3 className="Course-header-side">Duration</h3>
            <p className="Course-side-details">{duration}</p>
          </div>
          <div>
            <div>
              <h3 className="Course-header-side">Price</h3>
                <p className="Course-side-details">
                  <strong style={{ color: 'white' }}>$400 monthly or $1,500 up front</strong>
                </p>
            </div>          
          </div>
        </div>
      </div>

      <div className="Course-bottom-section">
        <h2 className="Course-header">Prerequisites</h2>
        <p className="Course-details">
          {prerequisites
            .split(/(\[.*?\])/g)
            .map((part, index) => renderPrerequisites(part, index))}
        </p>

        <section className="Course-topics-section">
          <h2 className="Course-header">Topics</h2>
          <div className="Course-details">
            {Object.entries(course.topics).map(([topicTitle, topicContent]) => (
              <TopicSection
                key={topicTitle}
                title={topicTitle}
                content={topicContent}
              />
            ))}
          </div>
        </section>

        <h2 className="Course-header">Material Requirements</h2>
        <div className="Course-details">
          <ul className="Course-topics-list">
            {common.materialRequirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </ul>
        </div>

        <h2 className="Course-header">Professionalism</h2>
        <p className="Course-details">{common.professionalism}</p>
        <h2 className="Course-header">Graduation Requirements</h2>
        <p className="Course-details">{common.graduationRequirements}</p>
      </div>
    </div>
  );
};

export default JavaAndSpringBoot;
