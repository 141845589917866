import React, { useCallback, useEffect, useState } from 'react';
import './styles/ContactUs.css';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import environmentVariables from '../EnvironmentVariables.json';
import Captcha from './Captcha';

import coursesInfo from '../../data/coursesInfo.json';

const FreeConsultation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [course, setCourse] = useState('');
  const [message, setMessage] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);

  const navigate = useNavigate();

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const typedPhoneNumber = input.replace(/\D/g, ''); // Remove non-numeric characters

    // Phone Number 10-digit limit
    if (typedPhoneNumber.length <= 10) {
      setPhone(typedPhoneNumber.slice(0, 10));
    }
  };

  // Format phoneNumber with hyphens
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  const handleCaptchaChange = useCallback((value) => {
    setCaptchaValue(value);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert('Please complete the CAPTCHA before submitting.');
      return;
    }

    // ATA EmailJS service ID, template ID, and public key
    const serviceId = environmentVariables.serviceId;
    const templateId = environmentVariables['freeConsult-templateId'];
    const publicKey = environmentVariables.publicKey;

    const templateParams = {
      from_first_name: firstName,
      from_last_name: lastName,
      from_email: email,
      to_name: 'Farhad',
      from_phone: formatPhoneNumber(phone),
      course: course,
      message: message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setCourse('');
        setMessage('');

        // Redirect to the main page after successfully sending the email
        navigate('/');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  const courseOptions = coursesInfo[0].courses.map((course) => (
    <option key={course.id} value={course.courseName}>
      {course.courseName}
    </option>
  ));

  return (
    <>
      <div className="containers">
        <img src="img/shape.png" className="square" alt="" />
        <div className="form">
          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form
              onSubmit={handleSubmit}
              action="index.html"
              autoComplete="off"
            >
              <div className="title-container">
                <h3 className="title">Free Consult Request</h3>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="first_name"
                  className="input"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="last_name"
                  className="input"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  placeholder="Phone"
                  value={phone}
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="input-container">
                <select
                  id="courseSelect"
                  className="input"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                  required
                >
                  <option disabled value="">
                    Select your course
                  </option>
                  <option value="Full-Stack Web Development">
                    Full-Stack Web Development
                  </option>
                  <option value="Back-End Java and AWS">
                    Back-End Java and AWS
                  </option>
                  <option value="Cybersecurity">
                    Cybersecurity
                  </option>
                  {/* {courseOptions} */}
                </select>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  placeholder="How can we help?"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="captcha-and-button">
                <div className="contactUs-captcha">
                  <Captcha onChange={handleCaptchaChange} />
                </div>
                <input type="submit" value="Send" className="btn" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeConsultation;
