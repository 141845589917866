import './styles/TutoringServices.css';
import { Link } from 'react-router-dom';

const TutoringServices = () => {
  return (
    <div className="tutoring-container">
      <section className="section tutoring-title-section">
        <div className="container tutoring-title-container">
          <div className="tutoring-title-content">
            <h1 className='white-text'>Personalized 1-on-1 tutoring</h1>
            <h3 className='white-text'>Online or In-Person</h3>
            <p className="tutoring-description">
              Whether our class schedule doesn’t fit your schedule, or you need
              a personalized touch, 1-on-1 lessons with our expert engineers is
              a great way to learn. All of our engineers have 10+ years of
              experience, making them an excellent resource for learning at your
              own pace on your own schedule.
            </p>
            <div className="pricing-box-top">
              <Link to="/TutorRequestForm" className="book-session-btn">
                Book a Session
              </Link>
              <p className="price-title">Price</p>
              <p className="price">$100.00 per hour</p>
              <p className="financial-assistance">
                Financial assistance available
              </p>
            </div>
          </div>
          <div className="tutor-image-container">
            <img src="1on1Tutor.jpg" alt="tutor img" className="tutor-image" />
          </div>
        </div>
      </section>

      <section className="section why-section">
        <div className="container why-container">
          <h3 className="tutor-section-title">
            Why choose one-on-one tutoring?
          </h3>
          <div className="features-grid">
            <div className="feature">
              <h4 className="feature-title">Flexible Scheduling</h4>
              <p className="feature-description">
                Our one-on-one tutoring service is designed for students who
                need a flexible learning schedule. Whether you're balancing a
                busy career, family commitments, or other obligations, we offer
                sessions that fit your timetable.
              </p>
            </div>
            <div className="feature">
              <h4 className="feature-title">Customized Learning</h4>
              <p className="feature-description">
                Benefit from a personalized learning experience tailored to your
                pace and specific goals. Our tutors adapt the curriculum to meet
                your needs, ensuring you grasp complex concepts and apply them
                effectively.
              </p>
            </div>
            <div className="feature">
              <h4 className="feature-title">Expert Tutors</h4>
              <p className="feature-description">
                Learn from the best! All our tutors are seasoned software
                engineers with over 10 years of industry experience. They bring
                real-world insights and practical knowledge to each session,
                helping you bridge the gap between theory and practice.
              </p>
            </div>
            <div className="feature">
              <h4 className="feature-title">Boost Your Confidence</h4>
              <p className="feature-description">
                Get the focused attention you need to overcome challenges and
                build confidence in your coding skills. Our tutors provide
                immediate feedback, answer your questions in real-time, and
                guide you through difficult topics.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section how-section">
        <div className="container how-container">
          <div className="how-content-wrapper">
            <h3 className="tutor-section-title">How it Works</h3>
            <div className="how-content">
              <ol className="step-list">
                <li>
                  Book a session: Click the "Book a Session" button. And select
                  online or in-person in Bellevue or Issaquah, WA.
                </li>
                <li>
                  Tutor pairing: Once you book, you'll be paired with a tutor
                  who matches your learning needs and goals.
                </li>
                <li>
                  Meet your tutor: One of our expert engineers will contact you
                  to schedule your first session and learn about your goals.
                </li>
                <li>
                  Personalized learning: Your tutor will develop a customized
                  learning plan based on your current skill level and
                  objectives. Whether you need help with specific topics or a
                  comprehensive review, we’ve got you covered.
                </li>
                <li>
                  Achieve your goals: Each tutoring session is at least one
                  hour. Progress at your own pace with ongoing support and
                  guidance. Track your improvements and achieve your learning
                  milestones with confidence.
                </li>
              </ol>
            </div>
          </div>
          <div className="pricing-box">
            <Link to="/TutorRequestForm" className="book-session-btn">
              Book a Session
            </Link>
            <p className="price-title">Price</p>
            <p className="price">$100.00 per hour</p>
            <p className="financial-assistance">
              Financial assistance available
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TutoringServices;
