import './styles/CourseCard.css';

function CourseCard({pic, title, description, url}) {

    const handleClick = () => {
        console.log('Div was clicked!');
        
        window.open(url,"_self")
    };

    return (
        <div className="course-card" onClick={handleClick}>
            <img className='course-card-image' src={pic} alt="course pic"></img>
            <h2 className='course-card-title'>{title}</h2>
            <p className='course-card-desc'>{description}</p>
        </div>
    );
}

export default CourseCard