import React, { useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import './Nav.css';
import coursesInfo from '../data/coursesInfo.json';

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isClassesDropdownOpen, setIsClassesDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
    if (isClassesDropdownOpen) {
      setIsClassesDropdownOpen(false);
    }
  };

  const toggleClassesDropdown = () => {
    setIsClassesDropdownOpen(!isClassesDropdownOpen);
  };

  const closeMenus = () => {
    setIsServicesDropdownOpen(false);
    setIsClassesDropdownOpen(false);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.closest('.nav') === null) {
        closeMenus();
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const logoPath = `${process.env.PUBLIC_URL}/ATA-logo.png`;

  const getCoursesBySubCategory = (subCategory) => {
    const courses = coursesInfo[0].courses;
    return courses.filter((course) => course.subCategory === subCategory);
  };

  const renderCourseLinks = (courses, closeDropdown) =>
    courses.map((course) => (
      <li key={course.id}>
        <CustomLink
          key={course.id}
          to={course.courseLink}
          closeDropdown={closeDropdown}
        >
          {course.courseName}
        </CustomLink>
      </li>
    ));

  const webDevelopmentCourses = getCoursesBySubCategory('web development');
  const javaCourses = getCoursesBySubCategory('java');
  const cybersecurityCourses = getCoursesBySubCategory(
    'cybersecurity engineering'
  );
  const continuingEducationCourses = getCoursesBySubCategory(
    'continuing education'
  );

  return (
    <header className="header">
      <nav className="nav container">
        <div className="nav__data">
          <Link to="/">
            <img className="Logo" src={logoPath} alt="Company Logo" />
          </Link>

          <div
            className={`nav__toggle ${isMenuOpen ? 'show-icon' : ''}`}
            id="nav-toggle"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <FontAwesomeIcon
                icon={faTimes}
                className="ri-close-line nav__close"
                style={{ marginRight: '15px' }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="ri-menu-line nav__burger"
                style={{ marginRight: '15px' }}
              />
            )}
          </div>
        </div>

        <div
          className={`nav__menu ${isMenuOpen ? 'show-menu' : ''}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="dropdown__item">
              <div
                className={`nav__link ${
                  isServicesDropdownOpen ? 'arrow-icon-open' : ''
                }`}
                onClick={toggleServicesDropdown}
              >
                Services
                <span className="arrow-icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </div>
              <ul
                className={`dropdown__menu services-dropdown ${
                  isServicesDropdownOpen ? 'show-dropdown' : ''
                }`}
              >
                <li className="dropdown__item">
                  <div
                    className={`nav__link ${
                      isClassesDropdownOpen ? 'arrow-icon-open' : ''
                    }`}
                    onClick={toggleClassesDropdown}
                  >
                    Classes
                    <span className="arrow-icon-classes">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </div>
                  <ul
                    className={`dropdown__menu classes-dropdown ${
                      isClassesDropdownOpen ? 'show-dropdown' : ''
                    }`}
                  >
                    <ul className="class-category">
                      {/* <li>
                        <CustomLink
                          to="/WebDevelopment"
                          closeDropdown={closeMenus}
                        >
                          <div className="category-title">Web Development</div>
                        </CustomLink>
                      </li> */}
                      <div className="course-names">
                        {renderCourseLinks(webDevelopmentCourses, closeMenus)}
                      </div>
                    </ul>
                    <ul className="class-category">
                      {/* <li>
                        <CustomLink to="/Java" closeDropdown={closeMenus}>
                          <div className="category-title">Java</div>
                        </CustomLink>
                      </li> */}
                      <div className="course-names">
                        {renderCourseLinks(javaCourses, closeMenus)}
                      </div>
                    </ul>
                    <ul className="class-category">
                      {/* <li>
                        <CustomLink
                          to="/Cybersecurity"
                          closeDropdown={closeMenus}
                        >
                          <div className="category-title">Cybersecurity</div>
                        </CustomLink>
                      </li> */}
                      <div className="course-names">
                        {renderCourseLinks(cybersecurityCourses, closeMenus)}
                      </div>
                    </ul>
                    <ul className="class-category">
                      {/* <li>
                        <CustomLink
                          to="/ContinuingEducation"
                          closeDropdown={closeMenus}
                        >
                          <div className="category-title">
                            Continuing Education
                          </div>
                        </CustomLink>
                      </li> */}
                      <div className="course-names">
                        {renderCourseLinks(
                          continuingEducationCourses,
                          closeMenus
                        )}
                      </div>
                    </ul>
                  </ul>
                </li>
                <div className="nav__link">
                  <li>
                    <CustomLink
                      to="/TutoringServices"
                      closeDropdown={closeMenus}
                    >
                      Tutoring
                    </CustomLink>
                  </li>
                </div>
              </ul>
            </li>
            <li>
              <CustomLink
                to="/FinancialAid"
                className="nav__link"
                closeDropdown={closeMenus}
              >
                Financial Aid
              </CustomLink>
            </li>
            <li>
              <CustomLink
                to="/FAQ"
                className="nav__link"
                closeDropdown={closeMenus}
              >
                FAQ
              </CustomLink>
            </li>
            <li>
              <CustomLink
                to="/ContactUs"
                className="nav__link"
                closeDropdown={closeMenus}
              >
                Contact Us
              </CustomLink>
            </li>
            <li>
              <CustomLink
                to="/Blog"
                className="nav__link"
                closeDropdown={closeMenus}
              >
                Blog
              </CustomLink>
            </li>
            <li>
              <CustomLink
                to="/ApplicationForm"
                className="nav__link apply-button"
                closeDropdown={closeMenus}
              >
                Sign Up
              </CustomLink>
            </li>
          </ul>
          <div className="nav__apply-button-container">
            <CustomLink
              to="/ApplicationForm"
              className="nav__link apply-button-large-screen"
              closeDropdown={closeMenus}
            >
              Sign Up
            </CustomLink>
          </div>
          {(isServicesDropdownOpen || isClassesDropdownOpen) && (
            <div onClick={closeMenus}></div>
          )}
        </div>
      </nav>
    </header>
  );
};

function CustomLink({ to, children, closeDropdown, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  const handleLinkClick = () => {
    if (closeDropdown) {
      closeDropdown();
    }
  };

  return (
    <Link
      to={to}
      className={isActive ? 'active' : ''}
      {...props}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
}

export default Nav;
