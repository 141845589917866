import React from 'react';
import './styles/FinancialAid.css';

const FinancialAid = () => {
  return (
    <div className="financialAid-container">
      <div className="container-finance">
        <div className="top-page-content">
          <div className="title-content">
            <h1 className='financial-aid-title-1'>Invest in Your Tech Career:</h1>
            <h1 className='financial-aid-title-1'>Financial Aid Solutions at</h1>
            <h1 className="title-blue">Ahmed Tech Academy</h1>
          </div>

          <div className="image-container">
            <img
              className="classroom-img"
              src="FinancialAidImage.jpg"
              alt="students in classroom"
            />
          </div>
        </div>
        <div className="finance-content">
          <p>
            At Ahmed Tech Academy, we believe that everyone deserves the
            opportunity to pursue a rewarding career in tech, regardless of
            their financial situation. To support this mission, we keep our
            prices low by default. If financial aid is still needed, we
            have programs that may be able to help. Contact us for details.
          </p>
          <br></br>
          <p>
            Our commitment to affordability extends beyond just financial
            assistance; we also provide personalized support throughout the
            application process to help you identify and apply for the best
            course options available. Whether you are looking to upskill
            for a new career or enhance your existing tech knowledge, our
            programs are built to ensure that your financial circumstances do
            not stand in the way of your success. Join us at Ahmed Tech Academy,
            where your dreams and aspirations are supported every step of the
            way.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FinancialAid;
