import './styles/Main.css'
import CourseCard from '../Courses/CourseCard';
import CardWebIcon from '../../assets/card_WebDevIcon.png'
import CardJavaIcon from '../../assets/card_JavaIcon.png'
import CardCybersecurityIcon from '../../assets/card_CybersecurityIcon.png'
import { Link } from 'react-router-dom';

function Main2() {
    return (
        <div className='landing-page-div'>
            <div className='landing-page-banner-div'>
                <div className='header-text-div'>
                    <h1 className='langing-page-h1'>Ahmed Tech Academy</h1>
                    <p className='landing-page-desc'>
                        Our mission is to offer the highest quality, affordable 
                        software engineering and cybersecurity training led by                         
                        instructors with 10+ years of experience.
                    </p>
                </div>
                <div className="course-cards-main-div">
                <CourseCard 
                    url="./FullStackWebDevelopment"
                    pic={CardWebIcon}
                    title="Full-Stack Web Development" 
                    description="Build advanced web applications using
                        Javascript, React, and Node.js. Learn how to 
                        utilize AI and cloud-based resources." 
                />
                <CourseCard 
                    url="./BackEndJavaAndAWS"
                    pic={CardJavaIcon}
                    title="Back-End Java and AWS" 
                    description="Become an expert at creating back-end 
                        software applications that connect to databases, 
                        integrate with cloud platforms and use AI." 
                />
                <CourseCard 
                    url="./Cybersecurity"
                    pic={CardCybersecurityIcon}
                    title="Cybersecurity" 
                    description="Become a Certified Information Systems 
                        Security ProfessionalLearn and learn the ins and 
                        outs of network security and cryptography." 
                /> 
            </div>
            <div className='header-btn-div'>
                <Link to="/FreeConsultation" className="landing-header-button">
                    Free Consultation
                </Link>
                <Link to="/TutoringServices" className="landing-header-button">
                    Private Tutor
                </Link>
            </div>
          </div> {/* .landing-page-banner-div */}
        </div>
    );
}

export default Main2